import { SVGProps } from "react";

export const ChangeFailPercentage = ({ width = 24, height = 24, ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#111"
        fillRule="nonzero"
        d="m20.584 14.552-1.793-.006a.403.403 0 0 1 .001-.806h.002l.764.003-3.478-3.19-4.19-1.745-4.25-.71a.403.403 0 0 1-.219-.111L3.118 3.688a.403.403 0 0 1 .57-.57l4.211 4.207 4.17.695a.41.41 0 0 1 .089.026l4.297 1.79a.403.403 0 0 1 .117.076l3.613 3.313.003-.866a.403.403 0 0 1 .403-.401h.002c.222 0 .402.181.401.404l-.006 1.692a.403.403 0 0 1-.391.498h-.013zm-8.563-5.104a.403.403 0 0 0-.403.403v10.746a.403.403 0 1 0 .806 0V9.851a.403.403 0 0 0-.403-.403zM7.724 8.73a.403.403 0 0 0-.403.403v11.463a.403.403 0 1 0 .806 0V9.134a.403.403 0 0 0-.403-.403zM3.409 6.474a.403.403 0 0 0-.403.403v13.72a.403.403 0 1 0 .805 0V6.877a.403.403 0 0 0-.402-.403zm17.188 8.759a.403.403 0 0 0-.403.403v4.961a.403.403 0 1 0 .806 0v-4.961a.403.403 0 0 0-.403-.403zm-4.315-3.278a.403.403 0 0 0-.403.403v8.239a.403.403 0 1 0 .806 0v-8.239a.403.403 0 0 0-.403-.403z"
        opacity={0.8}
      />
    </g>
  </svg>
);

export function DiagramGraphDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>diagram-graph-down</title>
      <g id="Graph_downard_trend" data-name="Graph downard trend">
        <path d="M974.847,641.08917l-99.1414-.32681a22.27729,22.27729,0,0,1,.07155-44.55446h.07542l42.24914.13923L725.77908,420.0515,494.13072,323.53231,259.04215,284.34992a22.27609,22.27609,0,0,1-12.07844-6.20939L9.01121,40.51676A22.27759,22.27759,0,0,1,40.49326,8.9883L273.40052,241.57455,503.9911,280.00663a22.31677,22.31677,0,0,1,4.906,1.40973l237.62377,99.0099a22.287,22.287,0,0,1,6.484,4.14218L952.81173,567.72479l.15833-47.86087a22.27815,22.27815,0,0,1,22.27529-22.20375h.07542a22.27814,22.27814,0,0,1,22.20374,22.35072l-.30892,93.51746a22.27557,22.27557,0,0,1-21.64536,27.56082c-.1141,0-.22771-.01378-.3418-.01547-.103.00145-.20256.01547-.306.01547ZM501.3186,358.911a22.278,22.278,0,0,0-22.27722,22.27723v594.0594a22.27722,22.27722,0,1,0,44.55445,0V381.18818A22.278,22.278,0,0,0,501.3186,358.911ZM263.69484,319.307a22.278,22.278,0,0,0-22.27723,22.27722V975.24758a22.27723,22.27723,0,1,0,44.55446,0V341.58421A22.278,22.278,0,0,0,263.69484,319.307ZM25.081,194.55258A22.278,22.278,0,0,0,2.80375,216.82981V975.24758a22.27723,22.27723,0,1,0,44.55446,0V216.82981A22.278,22.278,0,0,0,25.081,194.55258ZM975.576,678.71293a22.278,22.278,0,0,0-22.27723,22.27723V975.24758a22.27723,22.27723,0,0,0,44.55446,0V700.99016A22.278,22.278,0,0,0,975.576,678.71293ZM736.96217,497.52481A22.278,22.278,0,0,0,714.68494,519.802V975.24758a22.27723,22.27723,0,0,0,44.55446,0V519.802A22.278,22.278,0,0,0,736.96217,497.52481Z" />
      </g>
    </svg>
  );
}
