import { SVGProps } from "react";
export function DiagramStrategy(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
      <title>diagram-strategy</title>
      <g id="Operating_procedure" data-name="Operating procedure">
        <path d="M123.76238,245.04906A121.28713,121.28713,0,1,0,2.47525,123.76194,121.4243,121.4243,0,0,0,123.76238,245.04906Zm0-198.0198A76.73268,76.73268,0,1,1,47.0297,123.76194,76.81865,76.81865,0,0,1,123.76238,47.02926Z" />
        <path d="M400.82573,378.71243a22.278,22.278,0,0,0-22.27723,22.27723v198.0198a22.278,22.278,0,0,0,22.27723,22.27723H597.19407a22.278,22.278,0,0,0,22.27723-22.27723V400.98966a22.278,22.278,0,0,0-22.27723-22.27723Zm174.09112,198.0198H423.103V423.26689h151.8139Z" />
        <path d="M878.97973,766.00165a22.27892,22.27892,0,0,0-38.48623,0L724.98066,964.02145a22.27854,22.27854,0,0,0,19.24312,33.50286H975.24752a22.27854,22.27854,0,0,0,19.24312-33.50286Zm-95.97,186.96821,76.72687-131.5304,76.72494,131.5304Z" />
        <path d="M321.78218,126.23718H777.22772c96.904,0,175.74258,78.83857,175.74258,175.74258S874.13173,477.72233,777.22772,477.72233H744.87123L796.146,416.95114a24.75214,24.75214,0,1,0-37.83648-31.923l-83.5396,99.0099a24.74949,24.74949,0,0,0,0,31.923l83.5396,99.0099A24.75214,24.75214,0,0,0,796.146,583.048l-51.27473-60.77119h32.35649c121.47278,0,220.297-98.82426,220.297-220.297s-98.82425-220.297-220.297-220.297H321.78218a22.27722,22.27722,0,1,0,0,44.55445Z" />
        <path d="M613.59646,761.2658a24.75214,24.75214,0,1,0-37.83648,31.923L627.03471,853.96H222.77228c-96.904,0-175.74258-74.39666-175.74258-165.84159s78.83857-165.84158,175.74258-165.84158H301.9802a22.27723,22.27723,0,0,0,0-44.55446H222.77228c-121.47278,0-220.297,94.38235-220.297,210.396s98.82425,210.396,220.297,210.396H627.03471L575.76,959.28561a24.75214,24.75214,0,1,0,37.83648,31.923l83.5396-99.0099a24.74949,24.74949,0,0,0,0-31.923Z" />
      </g>
    </svg>
  );
}
