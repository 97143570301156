import { SVGProps } from "react";

export const TimeToRestore = ({ width = 24, height = 24, ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" {...props}>
    <g fill="#111" opacity={0.8}>
      <path d="M7.9 3.806h3.68v1.746c0 .223.188.403.42.403.232 0 .42-.18.42-.403V3.806h3.307c.231 0 .419-.18.419-.403a.411.411 0 0 0-.42-.403H7.902c-.232 0-.42.18-.42.403 0 .223.188.403.42.403zm9.44 15.042c1.335-1.303 2.16-3.089 2.16-5.057 0-1.968-.825-3.754-2.16-5.057a.37.37 0 0 0-.08-.076A7.632 7.632 0 0 0 12 6.582a7.632 7.632 0 0 0-5.26 2.076.37.37 0 0 0-.08.076c-1.335 1.303-2.16 3.089-2.16 5.057 0 1.968.825 3.754 2.16 5.057a.37.37 0 0 0 .08.076A7.632 7.632 0 0 0 12 21a7.632 7.632 0 0 0 5.26-2.076.37.37 0 0 0 .08-.076zm-1.09-1.543a.431.431 0 0 0-.594 0 .392.392 0 0 0 0 .57l.746.717a6.776 6.776 0 0 1-3.983 1.589v-1.017a.411.411 0 0 0-.419-.403c-.232 0-.42.18-.42.403v1.017a6.776 6.776 0 0 1-3.982-1.589l.746-.717a.392.392 0 0 0 0-.57.431.431 0 0 0-.593 0l-.746.717a6.25 6.25 0 0 1-1.653-3.828H6.41c.15 0 .288-.077.363-.201a.39.39 0 0 0 0-.404.423.423 0 0 0-.363-.2H5.352a6.25 6.25 0 0 1 1.653-3.83l.746.718a.432.432 0 0 0 .593 0 .392.392 0 0 0 0-.57l-.746-.717A6.776 6.776 0 0 1 11.58 7.4v1.017c0 .222.187.403.419.403.232 0 .42-.18.42-.403V7.4a6.776 6.776 0 0 1 3.982 1.589l-.746.717a.392.392 0 0 0 0 .57c.164.157.43.157.593 0l.746-.717a6.25 6.25 0 0 1 1.653 3.828H17.59c-.231 0-.42.18-.42.403 0 .223.189.403.42.403h1.058a6.25 6.25 0 0 1-1.653 3.828l-.746-.717z" />
      <path d="M17.601 11.48a.424.424 0 0 0-.484-.23c-.023.006-2.42.572-5.086 1.23-.528.13-1.1.325-1.354.79-.166.307-.165.668.004 1.102a.4.4 0 0 0 .019.04c.218.415.493.66.84.75.095.024.193.036.29.036.432 0 .86-.224 1.232-.457a587.53 587.53 0 0 0 4.387-2.763.394.394 0 0 0 .152-.498zm-4.995 2.585c-.405.252-.69.36-.848.318-.1-.025-.2-.131-.3-.314-.072-.195-.085-.338-.037-.425.075-.14.35-.269.818-.384.97-.24 1.904-.466 2.7-.658-.686.433-1.493.94-2.333 1.463z" />
    </g>
  </svg>
);

export function Stopwatch(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>stopwatch</title>
      <g id="Stopwatch">
        <path d="M282.17826,47.02919H477.72281v96.53465a22.27723,22.27723,0,0,0,44.55446,0V47.02919H698.01984a22.27723,22.27723,0,0,0,0-44.55446H282.17826a22.27723,22.27723,0,0,0,0,44.55446Z" />
        <path d="M783.74741,878.53324c70.92466-71.98595,114.76748-170.72464,114.76748-279.52386,0-108.80211-43.84524-207.54322-114.77328-279.52977a20.07478,20.07478,0,0,0-4.20708-4.2072C707.54761,244.34158,608.80457,200.49453,500,200.49453c-108.80574,0-207.54987,43.84814-279.537,114.78042a20.0706,20.0706,0,0,0-4.19982,4.19982c-70.93083,71.98692-114.778,170.73008-114.778,279.53461,0,108.80164,43.84476,207.54226,114.7722,279.52869a20.07576,20.07576,0,0,0,4.21457,4.21445C292.45839,953.67948,391.19877,997.52423,500,997.52423c108.80042,0,207.54-43.84378,279.52627-114.76978a20.07785,20.07785,0,0,0,4.2211-4.22121Zm-57.96225-85.244A22.27758,22.27758,0,0,0,694.2799,824.7945l39.62982,39.62983a352.50239,352.50239,0,0,1-211.63245,87.82415V896.03909a22.27723,22.27723,0,1,0-44.55446,0v56.20939a352.5026,352.5026,0,0,1-211.63209-87.82391l39.62946-39.63007a22.27758,22.27758,0,0,0-31.50526-31.50526l-39.62946,39.63007a352.49839,352.49839,0,0,1-87.82439-211.6327h56.20927a22.27723,22.27723,0,1,0,0-44.55445H146.76107a352.49985,352.49985,0,0,1,87.82342-211.63173l39.63043,39.631a22.27758,22.27758,0,0,0,31.50526-31.50526l-39.63043-39.63115a352.49927,352.49927,0,0,1,211.63306-87.82464v56.20927a22.27723,22.27723,0,1,0,44.55446,0V245.77041a352.49918,352.49918,0,0,1,211.63342,87.825L694.2799,373.2262a22.27758,22.27758,0,0,0,31.50526,31.50526l39.63067-39.63067A352.49992,352.49992,0,0,1,853.239,576.73216H797.02974a22.27722,22.27722,0,0,0,0,44.55445H853.239a352.5,352.5,0,0,1-87.824,211.63246Z" />
        <path d="M797.62922,471.2518a22.27324,22.27324,0,0,0-25.71357-12.68951c-1.26856.31134-128.5601,31.58068-270.28349,67.93588-28.02058,7.18982-58.47579,18.00936-71.904,43.74613-8.84708,16.95351-8.78133,36.86765.19725,60.88142a22.50748,22.50748,0,0,0,.9901,2.25866c11.57758,22.87284,26.18154,36.41321,44.64341,41.39272a59.00442,59.00442,0,0,0,15.44322,2.01307c22.92311,0,45.62577-12.40911,65.449-25.26106,122.76067-79.60628,232.00224-152.044,233.091-152.76725A22.27666,22.27666,0,0,0,797.62922,471.2518Zm-265.42,142.89334c-21.51145,13.95034-36.6588,19.88706-45.04757,17.61486-5.29084-1.42713-10.64743-7.27877-15.93054-17.39828-3.86371-10.74219-4.5386-18.64751-2.0034-23.50712,4.01454-7.69454,18.64364-14.82828,43.47733-21.19817,51.53736-13.2213,101.16027-25.76772,143.49088-36.39388C619.70146,557.199,576.85261,585.19634,532.2092,614.14514Z" />
      </g>
    </svg>
  );
}
