"use client";
import { useEffect, useMemo, useState, ChangeEvent, KeyboardEvent, FormEvent } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { Filter32, History32, Remove32 } from "@bphxd/ds-core-react/lib/icons";
import { Clock24, Search24 } from "@bphxd/ds-core-react/lib/icons";
import { trackEvent } from "../utils/event-tracker";
import { trace, TraceFlags } from "@opentelemetry/api";
import { debounce } from "lodash";
import { requestAccessToken } from "../utils/helpers/msal-helper";
import { ACCELERATE_APIS } from "../api/config";
import { isUrlInternal, Link } from "./Link";
import { useQuery } from "react-query";
import { fetchAdvancedSearchResults, submitSearchFeedback } from "../api/search/v2";
import { AdvancedSearchFilters, getSearchFilterByCategory } from "../views/search/search-filters";
import { ResultItemWithExtra } from "../api/search/types";
import { FilterIcon } from "../views/search/filter-item";
import { useDebounce } from "../hooks/use-debounce";
import { Collapse } from "reactstrap";
import { sAxelUrlAndQuery } from "./search-types";
import { DateTime } from "luxon";
import { useLocation } from "../hooks/use-location";
import { Keys } from "../utils/event-enums";
import { useAppActions, useSearchIsActive } from "../hooks/use-app-store";
import { IntroJsStep } from "../views/search/enums";
import { useMutation } from "@apollo/client";
import { LIST_CHATS, NEW_CHAT } from "../api/axel/gql";
import { AxelLogo32 } from "../icons/axel";
import { Event, ViewComponent } from "../telemetry/enums";
import { useQueryState } from "nuqs";
import { useRouter as useAppRouter } from "next/navigation";

export enum TestId {
  Input = "bp-search-bar-input",
  Clear = "bp-search-bar-clear",
  Collapse = "suggestions-collapse",
  Form = "bp-search-bar-form",
  Suggestions = "bp-search-bar-suggestions",
  Filter = "bp-search-bar-filter",
  AxelHistory = "bp-search-bar-axel-history-button",
}

const API = ACCELERATE_APIS.searchws;
const SUGGESTIONS_DEBOUNCE_DELAY = 150;
const SEARCH_DEBOUNCE_DELAY = 350;
const QUERY_LENGTH = 2;
const WS_SERVICE_URL = API.url;
const SUGGESTION_DEFAULT_INDEX = -1;

export function SearchBar() {
  const { setSearchIsActive, setIsSearchFormOpen, setCurrentPage } = useAppActions();
  const searchIsActive = useSearchIsActive();
  const [searchBarInput, setSearchBarInput] = useQueryState("s", { defaultValue: "" });
  const { router: pagesRouter } = useLocation();
  const appRouter = useAppRouter();
  const router = pagesRouter ?? appRouter;
  const isAuthenticated = useIsAuthenticated();

  const [token, setToken] = useState<string | void>();
  const [hasSuggestions, setHasSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const debouncedSearch = useDebounce(searchBarInput, SEARCH_DEBOUNCE_DELAY);
  const [results, setResults] = useState<ResultItemWithExtra[]>([]);
  const [suggestionsHighlightIndex, setSuggestionsHighlightIndex] = useState(SUGGESTION_DEFAULT_INDEX);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const { isLoading, data: searchResults } = useQuery(["searchBarResults", debouncedSearch], async () => {
    try {
      return await fetchAdvancedSearchResults({ s: debouncedSearch }, [], "5");
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      }
    }
  });

  const [requestNewChat] = useMutation<{ newChat: { chatId: string } }>(NEW_CHAT, {
    refetchQueries: [{ query: LIST_CHATS }],
  });

  useEffect(() => {
    if (searchResults && !isLoading) {
      setResults((searchResults?.items?.[0]?.ResultItems || []) as ResultItemWithExtra[]);
    }
  }, [searchResults, isLoading]);

  useEffect(() => {
    if (searchIsActive) {
      const socket = new WebSocket(`${WS_SERVICE_URL}?token=${token}&traceparent=${constructTraceParent()}`);

      socket.addEventListener("open", function () {
        setWs(socket);
      });

      socket.addEventListener("close", function () {
        setWs(null);
      });

      socket.addEventListener("message", function (event) {
        const { items } = JSON.parse(event?.data);
        if (items?.length > 0) {
          setHasSuggestions(true);
          setSuggestions(items.slice(0, 5));
        }
      });

      return () => {
        socket.close();
      };
    }
  }, [token, searchIsActive]);

  function onFocus() {
    setSearchIsActive(true);
    document.body.className = "modal-open";
  }

  const onKeyDown = (event: KeyboardEvent) => {
    const eventKey = event.key;
    if ((event.target as HTMLInputElement).value === "") {
      setSearchIsActive(true);
    }
    if (eventKey === Keys.Enter || eventKey === Keys.Escape) {
      setSearchIsActive(false);
    }
  };

  const clearSearchBar = () => {
    setHasSuggestions(false);
    setSearchBarInput("");
  };

  const openSearchModal = () => {
    trackEvent({
      name: Event.NewSearchModal,
      component: ViewComponent.SearchBar,
      searchQuery: searchBarInput,
    });
    setIsSearchFormOpen(true);
  };

  const openHistory = () => {
    trackEvent({
      name: Event.ButtonClick,
      component: ViewComponent.SearchBar,
      feature: "axel_history",
    });
    router?.push("/axel");
  };

  function constructTraceParent() {
    // TODO: Move into telemetry utils -- can we use the equivalent method in otel.js for this?
    const activeSpan = trace.getActiveSpan();
    if (!activeSpan) {
      return "";
    }
    const { traceFlags, traceId, spanId } = activeSpan.spanContext();
    return `00-${traceId}-${spanId}-0${Number(traceFlags ?? TraceFlags.NONE).toString(16)}`;
  }

  const debouncedSearchBar = useMemo(
    () =>
      debounce((event) => {
        const eventTargetValue = event.target.value;
        if (ws !== null && eventTargetValue.length >= QUERY_LENGTH) {
          ws.send(eventTargetValue);
        }
      }, SUGGESTIONS_DEBOUNCE_DELAY),
    [ws],
  );

  function newAxelChatAndQuery(query: string) {
    requestNewChat().then((response) => {
      const newChatId = response.data?.newChat.chatId;
      trackEvent({
        name: Event.SearchSubmitted,
        component: ViewComponent.SearchBar,
        query: query,
      });
      router?.push(sAxelUrlAndQuery(query, newChatId));
    });
  }

  const handleSearch = (event?: FormEvent) => {
    event?.preventDefault();
    const searchIsEmpty = searchBarInput.replace(/^\s+$/, "") === "";
    if (!searchIsEmpty) {
      newAxelChatAndQuery(searchBarInput);

      // disable scroll block on search submit
      document.body.className = "";
      // for a new search display page 1 of the results
      setCurrentPage(1);
    } else {
      setSearchBarInput("");
      setHasSuggestions(false);
    }
  };

  const onChange = (event: ChangeEvent) => {
    const eventTargetValue = (event.target as HTMLInputElement).value;
    setSearchBarInput(eventTargetValue);
    if (eventTargetValue !== "") {
      debouncedSearchBar(event);
    }
  };

  const handleBlur = () => {
    debouncedSearchBar.cancel();
    setSearchIsActive(false);
    document.body.className = "";
  };

  const modifyHighlightIndex = (
    highlightIndex: number,
    boundaryIndex: number,
    resetIndex: number,
    stepSize: number,
  ) => {
    if (highlightIndex === boundaryIndex || highlightIndex === SUGGESTION_DEFAULT_INDEX) {
      return resetIndex;
    } else {
      return highlightIndex + stepSize;
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const suggestionsLength = suggestions?.length;
    const allSuggestionsLength = suggestionsLength + results?.length;
    const firstSuggestionIndex = 0;
    const lastSuggestionIndex = allSuggestionsLength - 1;
    const stepAmount = 1;
    if (allSuggestionsLength > 0) {
      const eventKey = event.key;
      const isEventKeyArrowDown = eventKey === Keys.ArrowDown;
      const isEventKeyArrowUp = eventKey === Keys.ArrowUp;
      if (isEventKeyArrowDown || isEventKeyArrowUp) {
        event.preventDefault();
        const highlightIndex = modifyHighlightIndex(
          suggestionsHighlightIndex,
          isEventKeyArrowDown ? lastSuggestionIndex : firstSuggestionIndex,
          isEventKeyArrowDown ? firstSuggestionIndex : lastSuggestionIndex,
          isEventKeyArrowDown ? stepAmount : -stepAmount,
        );
        setSuggestionsHighlightIndex(highlightIndex);
        setSearchBarInput(suggestions[highlightIndex] ?? "");
      } else if (
        eventKey === Keys.Enter &&
        !suggestions[suggestionsHighlightIndex] &&
        suggestionsHighlightIndex !== SUGGESTION_DEFAULT_INDEX
      ) {
        // Here we only want to deal with top five highlighted items. We don't want to deal with any suggestion
        // highlight or the default highlight (SUGGESTION_DEFAULT_INDEX) as that's for handleSearch.
        const searchLink = results[suggestionsHighlightIndex - suggestionsLength]?.Accelerate?.SearchLink;
        if (isUrlInternal(searchLink)) {
          router?.push(searchLink);
        } else {
          window.open(searchLink);
        }
      } else {
        // The wrong suggestion is at the top from the BE so the ui hides the highlight to be above the top index.
        // TODO for the BE please always return the actual searchBarInput value at the top so it can be highlighted.
        setSuggestionsHighlightIndex(SUGGESTION_DEFAULT_INDEX);
      }
      onFocus();
    }
    onKeyDown(event);
  };

  const handleMouseEnter = (index: number) => {
    return () => {
      setSuggestionsHighlightIndex(index);
    };
  };

  useEffect(() => {
    async function requestToken() {
      setToken(await requestAccessToken(API.scopes));
    }
    if (isAuthenticated) {
      requestToken();
    }
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearchBar.cancel();
    };
  }, [debouncedSearchBar]);

  return (
    <div className="bp-search-bar">
      <form onSubmit={handleSearch} data-testid={TestId.Form}>
        <AxelLogo32 />
        <input
          data-testid={TestId.Input}
          type="text"
          value={searchBarInput}
          placeholder="Ask me anything..."
          onChange={onChange}
          onClick={onFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
        <div className="d-flex">
          <div introjs-step={IntroJsStep.FilterIcon}>
            <Filter32
              className="cursor-pointer full-opacity-on-hover"
              data-testid={TestId.Filter}
              onClick={openSearchModal}
            />
          </div>
          <div introjs-step={IntroJsStep.AxelHistory}>
            <History32
              className="cursor-pointer full-opacity-on-hover"
              onClick={openHistory}
              data-testid={TestId.AxelHistory}
            />
          </div>
        </div>
        {searchBarInput?.length > 0 && (
          <Remove32
            className="cursor-pointer full-opacity-on-hover"
            data-testid={TestId.Clear}
            onClick={clearSearchBar}
          />
        )}
      </form>
      <div
        className={`pb-4 bg-primary rounded-bottom-7 shadow-sm w-100 bp-search-suggestions ${
          searchIsActive && (hasSuggestions || results.length > 0) ? "fadeIn" : "fadeOut"
        }`}
        data-testid={TestId.Suggestions}
      >
        {suggestions?.length > 0 && (
          <ul>
            {suggestions?.map((suggestion, i) => (
              <li key={i}>
                <Link
                  href="#"
                  onClick={() => newAxelChatAndQuery(suggestion)}
                  className={`px-4 py-4 text-default d-flex gap-3${
                    suggestionsHighlightIndex === i ? " bg-tertiary text-decoration-none" : ""
                  }`}
                  onMouseEnter={handleMouseEnter(i)}
                >
                  <Clock24 />
                  <span>{suggestion}</span>
                </Link>
              </li>
            ))}
          </ul>
        )}
        <Collapse isOpen={!isLoading} data-testid={TestId.Collapse}>
          {results?.length > 0 && (
            <ul>
              {results?.map((result, i) => {
                const resultAccelerate = result?.Accelerate;
                const searchFilter = getSearchFilterByCategory(resultAccelerate?.DocumentMetadata?._category);
                const suggestionsLength = suggestions?.length;
                const borderClasses = i === 0 && suggestionsLength > 0 ? " border-top border-light" : "";
                const highlightClasses =
                  suggestionsHighlightIndex === suggestionsLength + i ? " bg-tertiary highlight" : "";

                return (
                  <li key={i} className="collapse show">
                    <Link
                      onClick={async () => {
                        await submitSearchFeedback(
                          result?.QueryId,
                          result?.Id,
                          `${DateTime.now().toISO({ includeOffset: false })}`,
                        );
                        console.info("Feedback submitted for ", result?.DocumentTitle?.Text);
                      }}
                      href={resultAccelerate?.SearchLink}
                      className={`px-4 py-4 text-default d-flex align-items-center gap-3${borderClasses}${highlightClasses}`}
                      onMouseEnter={handleMouseEnter(suggestionsLength + i)}
                      hideExternalLinkIcon={true}
                    >
                      <FilterIcon type={searchFilter as AdvancedSearchFilters} />
                      <div>
                        <p className="mb-0">{resultAccelerate?.SearchTitle}</p>
                        <p className="mb-0 fw-light">
                          {searchFilter
                            ? AdvancedSearchFilters[searchFilter].replace(/s$/, "")
                            : AdvancedSearchFilters.Documents}
                        </p>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </Collapse>
        {searchBarInput && (
          <Link
            href="#"
            onClick={() => newAxelChatAndQuery(searchBarInput)}
            className="px-4 py-4 text-default border-top border-light d-flex align-items-center gap-3"
          >
            <Search24 />
            <p className="mb-0">Browse all search results for "{searchBarInput}" Press ENTER</p>
          </Link>
        )}
      </div>
    </div>
  );
}
