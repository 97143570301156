"use client";
import { PropsWithChildren, ReactNode, useEffect } from "react";
import { Helmet } from "react-helmet-async";

import { useLocation } from "../hooks/use-location";

import { trackPageview } from "../utils/event-tracker";
import { env } from "next-runtime-env";

import { Nav, Navbar, NavItem } from "reactstrap";
import { Left24 } from "@bphxd/ds-core-react/lib/icons";
import { Loader } from "./spinners/loading-spinner";
import { Button } from "./button";
import { Link } from "./Link";
import { LinkButton } from "./link-button";

function Breadcrumb({
  link,
  text,
  backWithHistory = false,
}: {
  link: string;
  text: string;
  backWithHistory?: boolean;
}) {
  const { router } = useLocation();
  return (
    <div className="d-flex align-items-center" style={{ minHeight: 48 }}>
      {backWithHistory ? (
        <div onClick={router?.back}>
          <span className="navbar-breadcrumb">
            <Left24 /> Back
          </span>
        </div>
      ) : (
        <Link href={link} name="breadcrumb">
          <span className="navbar-breadcrumb">
            <Left24 /> {text}
          </span>
        </Link>
      )}
    </div>
  );
}

function ButtonContent({ icon, cta }: Pick<CTAProps, "icon" | "cta">) {
  return (
    <>
      {icon} {cta}
    </>
  );
}

function ButtonCTA({ name, action, icon, cta }: Omit<CTAProps, "destination">) {
  return (
    <Button
      onClick={action}
      name={name}
      data-testid={name?.toLowerCase()?.replace(/\s/g, "")}
      level="primary"
      theme="standard"
      rounded="pill"
      size="lg"
    >
      <ButtonContent icon={icon} cta={cta} />
    </Button>
  );
}

function LinkCTA({ name, action, destination, icon, cta }: CTAProps) {
  return destination ? (
    <LinkButton
      onClick={action}
      href={destination}
      name={name}
      level="primary"
      theme="standard"
      rounded="pill"
      size="lg"
    >
      <ButtonContent icon={icon} cta={cta} />
    </LinkButton>
  ) : (
    <></>
  );
}

type CTAProps = {
  name: string;
  cta: string;
  action: () => void;
  destination: string;
  icon?: ReactNode;
};

function CTA({ name, cta, action, destination, icon }: CTAProps) {
  const ctaName = `${name?.replace(/\s/g, "_")}-cta`;
  return destination ? (
    <LinkCTA action={action} destination={destination} name={ctaName} icon={icon} cta={cta} />
  ) : (
    <ButtonCTA action={action} name={ctaName} icon={icon} cta={cta} />
  );
}

type PageTemplateProps = {
  isLoading?: boolean;
  name?: string;
  title?: string;
  backgroundClassName?: string;
  breadcrumb?: string;
  breadcrumbLink?: string;
  breadcrumbHistoryBack?: boolean;
  pageHeader?: string;
  primaryCta?: string;
  primaryCtaAction?: () => void;
  primaryCtaDestination?: string;
  secondaryCta?: string;
  secondaryCtaAction?: () => void;
  secondaryCtaDestination?: string;
  isKhubPage?: boolean;
  trackingEventProps?: { [key: string]: unknown };
};

function HeadProps({ title, deployEnv }: { title?: string; deployEnv?: string }) {
  return (
    <Helmet>
      {title ? (
        <title>
          {deployEnv ? `<${deployEnv}> ` : ""}
          {title} | bp Accelerate
        </title>
      ) : (
        <title>{deployEnv ? `<${deployEnv}> ` : ""}bp Accelerate</title>
      )}
    </Helmet>
  );
}

export function PageSection({ children }: { maxWidth?: boolean; children: ReactNode }) {
  return <div className="container-xxl">{children}</div>;
}

export function PageWithNoPadding({
  name,
  title,
  backgroundClassName,
  trackingEventProps,
  children,
}: PropsWithChildren<Pick<PageTemplateProps, "name" | "title" | "backgroundClassName" | "trackingEventProps">>) {
  const deployEnvVar = env("NEXT_PUBLIC_DEPLOY_ENVIRONMENT");
  const deployEnv = deployEnvVar ? deployEnvVar.replace(/prod/, "") : "dev";

  useEffect(() => {
    if (name) {
      trackPageview({ name, ...trackingEventProps });
    }
  }, [name, JSON.stringify(trackingEventProps)]);

  return (
    <>
      <HeadProps title={title} deployEnv={deployEnv} />
      <div className={`${backgroundClassName ?? ""} pb-9 h-100`}>{children}</div>
    </>
  );
}

export default function Page({
  isLoading = false,
  name = "",
  title = "",
  backgroundClassName = "",
  breadcrumb = "",
  breadcrumbLink = "",
  breadcrumbHistoryBack = false,
  pageHeader = "",
  primaryCta = "",
  primaryCtaAction = () => {},
  primaryCtaDestination = "",
  secondaryCta = "",
  secondaryCtaAction = () => {},
  secondaryCtaDestination = "",
  isKhubPage = false,
  trackingEventProps = {},
  children,
}: PropsWithChildren<PageTemplateProps>) {
  const deployEnvVar = env("NEXT_PUBLIC_DEPLOY_ENVIRONMENT");
  const deployEnv = deployEnvVar ? deployEnvVar.replace(/prod/, "") : "dev";

  useEffect(() => {
    if (name) {
      trackPageview({ name, isKhubPage, ...trackingEventProps });
    }
  }, [name, JSON.stringify(trackingEventProps)]);

  return (
    <>
      <HeadProps title={title} deployEnv={deployEnv} />
      {isLoading ? (
        <div
          data-testid="loading-screen"
          className="d-flex flex-column justify-content-center align-items-center h-100"
        >
          <Loader size="lg" />
        </div>
      ) : (
        <div className={`${backgroundClassName} pb-9 h-100`}>
          <PageSection>
            {(pageHeader || breadcrumb || breadcrumbHistoryBack) && (
              <Navbar
                light
                expand="md"
                container={false}
                aria-label="content-header"
                data-testid="top-nav"
                className="col-md-12 py-0 pt-4 align-items-center"
                style={{ backgroundColor: "inherit" }}
              >
                {(breadcrumb || breadcrumbHistoryBack) && (
                  <Breadcrumb link={breadcrumbLink} text={breadcrumb} backWithHistory={breadcrumbHistoryBack} />
                )}
                {pageHeader && <h1 className="mb-0">{pageHeader}</h1>}
                <Nav className="me-auto" navbar></Nav>
                <Nav navbar className="me-4 nav-button">
                  <NavItem>
                    {secondaryCta ? (
                      <CTA
                        name={name}
                        cta={secondaryCta}
                        action={secondaryCtaAction}
                        destination={secondaryCtaDestination}
                      />
                    ) : (
                      <></>
                    )}
                    {primaryCta ? (
                      <CTA name={name} cta={primaryCta} action={primaryCtaAction} destination={primaryCtaDestination} />
                    ) : (
                      <></>
                    )}
                  </NavItem>
                </Nav>
              </Navbar>
            )}
            {children}
          </PageSection>
        </div>
      )}
    </>
  );
}
