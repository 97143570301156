import { SVGProps } from "react";

export function CodeTags(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>code-tags</title>
      <g id="Code_tags" data-name="Code tags">
        <path d="M757.40832,819.30886a22.27945,22.27945,0,0,1-17.94748-35.454L947.62142,500,739.46084,216.14422a22.27755,22.27755,0,1,1,35.92977-26.34785L993.21239,486.82608a22.28238,22.28238,0,0,1,0,26.34881L775.39061,810.20459A22.25136,22.25136,0,0,1,757.40832,819.30886Z" />
        <path d="M242.59261,819.30886a22.25046,22.25046,0,0,1-17.98325-9.10427L6.78757,513.17489a22.27938,22.27938,0,0,1,0-26.34881L224.60936,189.79637a22.27755,22.27755,0,1,1,35.92976,26.34785L52.37757,500,260.53912,783.85481a22.28,22.28,0,0,1-17.94651,35.454Z" />
        <path d="M381.20454,997.53055a22.29871,22.29871,0,0,1-21.62844-27.686l237.62376-950.495a22.27715,22.27715,0,0,1,43.224,10.806L402.80011,980.65053A22.28757,22.28757,0,0,1,381.20454,997.53055Z" />
      </g>
    </svg>
  );
}

export const CodePercentage = ({ width = 24, height = 24, ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path
      fill="#111"
      d="M16.657 17.776a.403.403 0 0 1-.325-.641L20.097 12l-3.765-5.135a.403.403 0 0 1 .65-.477l3.94 5.373a.403.403 0 0 1 0 .477l-3.94 5.373a.402.402 0 0 1-.325.165zm-9.314 0a.402.402 0 0 1-.325-.165l-3.94-5.373a.403.403 0 0 1 0-.477l3.94-5.373a.403.403 0 1 1 .65.477L3.903 12l3.765 5.135a.403.403 0 0 1-.325.641zM9.851 21a.403.403 0 0 1-.39-.5l4.297-17.195a.403.403 0 0 1 .782.195l-4.298 17.195a.403.403 0 0 1-.39.305z"
      opacity={0.8}
    />
  </svg>
);
