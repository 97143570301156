"use client";
import { PropsWithChildren, useEffect } from "react";
import { DateTime } from "luxon";

import { useLocalStorage } from "usehooks-ts";

import { Alert } from "@bphxd/ds-core-react";

type DismissibleBannerProps = {
  color: "danger" | "warning" | "info" | "success" | "neutral";
  name: string;
  show: boolean;
};

/**
 * Banner will reappear after 1 month has passed from when it is dismissed.
 */
export function DismissableBanner({ color, name, show, children }: PropsWithChildren<DismissibleBannerProps>) {
  const [bannerDismissedDate, setBannerDismissedDate] = useLocalStorage<number | null>(`display-${name}-banner`, null);

  useEffect(() => {
    if (bannerDismissedDate) {
      const dismissedDate = DateTime.fromMillis(Number(bannerDismissedDate));
      if (dismissedDate.plus({ month: 1 }).toMillis() < DateTime.now().toMillis()) {
        setBannerDismissedDate(null);
      }
    }
  }, [bannerDismissedDate]);

  return (
    <div className="d-flex flex-column">
      <Alert
        color={color}
        isOpen={show && !bannerDismissedDate}
        dismissible
        onClose={() => setBannerDismissedDate(DateTime.now().toMillis())}
      >
        {children}
      </Alert>
    </div>
  );
}
