import { SVGProps } from "react";
export function RobotWave(props: SVGProps<SVGSVGElement>) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <path d="M500,556.93A42.08,42.08,0,1,0,542.08,599,42.13,42.13,0,0,0,500,556.93Z" />
      <path d="M401,556.93A42.08,42.08,0,1,0,443.07,599,42.13,42.13,0,0,0,401,556.93Z" />
      <path d="M599,556.93A42.08,42.08,0,1,0,641.09,599,42.13,42.13,0,0,0,599,556.93Z" />
      <path d="M977.72,242.57a61.85,61.85,0,0,0-71.58-61.11L832.73,106.7c-.31-.31-.64-.58-1-.87A71.78,71.78,0,0,0,757.19,3.19a22.28,22.28,0,1,0,6.27,44.11,27.23,27.23,0,1,1-22.91,31.93,22.27,22.27,0,0,0-43.81,8.07,71.83,71.83,0,0,0,104.07,50.48,1.79,1.79,0,0,0,.13.14l65.82,67a61.78,61.78,0,0,0,17.41,90.76l-41.5,83H553.59a22.25,22.25,0,0,0,0-34.65h73.17a73.82,73.82,0,0,0,73.74-73.73V155.41a73.82,73.82,0,0,0-73.74-73.73H522.28V24.75a22.28,22.28,0,0,0-44.56,0V81.68H373.24a73.82,73.82,0,0,0-73.74,73.73V270.33a73.82,73.82,0,0,0,73.74,73.73h73.17a22.25,22.25,0,0,0,0,34.65H203a22.27,22.27,0,0,0-20.86,14.46L122.7,551.58a22.2,22.2,0,0,0,14.53,29.14l-13.06,78.35a61.87,61.87,0,0,0,2.55,118.29L156,865.09a73.16,73.16,0,0,0-10.16,8.44,71.8,71.8,0,0,0,0,101.52,72.24,72.24,0,0,0,50.9,21,71.39,71.39,0,0,0,10-.7,22.28,22.28,0,0,0-6.26-44.11,27.23,27.23,0,1,1,22.9-31.94,22.28,22.28,0,0,0,43.82-8.06,71.76,71.76,0,0,0-68.4-58.69l-26.63-79.87A61.84,61.84,0,0,0,169,661.41l13.29-79.73H280.6l59.06,259.89a22.28,22.28,0,0,0,21.73,17.34H638.61a22.28,22.28,0,0,0,21.73-17.34L719.4,581.68H797a22.28,22.28,0,0,0,20.86-14.45l59-157.33,53.6-107.21A62,62,0,0,0,977.72,242.57ZM344.06,270.33V155.41a29.21,29.21,0,0,1,29.18-29.17H626.76a29.21,29.21,0,0,1,29.18,29.17V270.33a29.2,29.2,0,0,1-29.18,29.17H584a100.87,100.87,0,0,0,17.52-56.93,22.28,22.28,0,0,0-44.56,0,56.93,56.93,0,1,1-113.86,0,22.28,22.28,0,0,0-44.56,0A100.87,100.87,0,0,0,416,299.5H373.24A29.2,29.2,0,0,1,344.06,270.33Zm589.11-27.76a17.33,17.33,0,1,1-17.33-17.32A17.34,17.34,0,0,1,933.17,242.57ZM160.89,717.82a17.33,17.33,0,1,1-17.35-17.32h.07A17.33,17.33,0,0,1,160.89,717.82Zm14.82-180.69,42.7-113.86h26.18l25.88,113.86ZM620.83,814.36H379.17L290.28,423.27H709.72ZM781.59,537.13H729.53l25.88-113.86h68.88Z" />
    </svg>
  );
}
