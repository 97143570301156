"use client";
import { useState, useEffect } from "react";

import { trackEvent } from "../../utils/event-tracker";
import { textToSlug } from "../../utils/helpers/utils";
import { useLocation } from "../../hooks/use-location";
import { DropdownEnum, useMenuData } from "./use-menu-data";

import { Navbar, Collapse, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Home24, Down16 } from "@bphxd/ds-core-react/lib/icons";
import { Link } from "../../components/Link";
import { LinkButton } from "../../components/link-button";
import { NewFlag } from "../../components/badges/new-flag";

export const MegaMenu2 = () => {
  const menuData2 = useMenuData();

  const [openDropdown, setOpenDropdown] = useState<string | undefined>(undefined);
  const [activeNavItem, setActiveNavItem] = useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<string | undefined>(undefined);
  const { pathname } = useLocation();

  const toggleDisplay = () => setOpenDropdown(undefined);

  const setActiveDropdown = (id: string | undefined) => {
    if (id !== openDropdown) {
      setOpenDropdown(id);
    }
  };

  const setActiveNavItems = (id: string) => {
    if (id !== activeNavItem) {
      setActiveNavItem(id);
    }
  };

  const handleOnMouseEnter = (id: string) => {
    setActiveNavItems(id);
  };

  const handleOnMouseLeave = () => {
    setActiveNavItem(undefined);
  };
  const splitPaths = pathname?.split("/") ?? [];

  const productPaths = ["my-products", "all-products", "product"];
  const toolsPath = "tools";

  const productPathsExist = productPaths.some((path) => {
    return splitPaths.includes(path);
  });

  const dropDownNewFlagDate = menuData2.map(({ featureColumn, columns }) => {
    const {
      dropDownMenu: { links },
    } = featureColumn;
    const featureColumnNewFlagDate = links.reduce((acc, link) => {
      if (link.newFlag) {
        const { startDate } = link.newFlag;
        if (acc > startDate) {
          return acc;
        } else {
          return startDate;
        }
      } else {
        return acc;
      }
    }, "");

    const columnNewFlagDate = columns.reduce((acc, { dropDownMenu: { links } }) => {
      const linkNewFlag = links.reduce((acc, { newFlag }) => {
        if (newFlag) {
          const { startDate } = newFlag;
          if (acc > startDate) {
            return acc;
          } else {
            return startDate;
          }
        } else {
          return acc;
        }
      }, "");
      if (linkNewFlag > acc) {
        return linkNewFlag;
      } else {
        return acc;
      }
    }, "");

    return featureColumnNewFlagDate > columnNewFlagDate ? featureColumnNewFlagDate : columnNewFlagDate;
  });

  useEffect(() => {
    if (splitPaths.includes(toolsPath)) {
      setCurrentPage(DropdownEnum.Tools);
    } else if (productPathsExist) {
      setCurrentPage(DropdownEnum.Products);
    } else if (pathname?.startsWith("/dac")) {
      setCurrentPage(DropdownEnum.Documentation);
    } else if (pathname?.startsWith("/knowledge")) {
      setCurrentPage(DropdownEnum.Documentation);
    } else if (pathname?.startsWith("/admin")) {
      setCurrentPage(DropdownEnum.Admin);
    } else {
      setCurrentPage(undefined);
    }
  }, [pathname]);

  return (
    <Navbar className="border-top border-bottom d-none d-lg-flex py-0 mega-menu" expand container={false}>
      <div className="container-xxl">
        <Link name="home-mega-menu-toggle-link" href="/" className="text-dark">
          <Home24 />
        </Link>

        <Collapse navbar>
          <Nav navbar>
            {menuData2.map((category, idx) => {
              const { id, dropDownToggle, featureColumn, columns } = category;
              const {
                dropDownMenu: { header, description, links },
              } = featureColumn;

              return (
                <UncontrolledDropdown
                  data-testid={`navItem-${dropDownToggle}`}
                  active={id === openDropdown}
                  isOpen={id === openDropdown}
                  toggle={toggleDisplay}
                  onClick={() => {
                    trackEvent({
                      name: `${textToSlug(dropDownToggle)}-mega-menu-toggle-link_click`,
                    });
                    setActiveDropdown(id);
                  }}
                  onKeyDownCapture={(e) => {
                    if (e.key === "Enter") {
                      trackEvent({
                        name: `${textToSlug(dropDownToggle)}-mega-menu-toggle-link_click`,
                      });
                      setActiveDropdown(id === openDropdown ? undefined : id);
                    }
                  }}
                  key={id}
                  className="position-static py-3"
                  aria-current={`${dropDownToggle === currentPage && "page"}`}
                  inNavbar
                  nav
                  tabIndex={0}
                  style={{
                    borderBottom: `${id === openDropdown ? "1px solid rgba(17, 17, 17, 0.9)" : ""}`,
                  }}
                >
                  {dropDownNewFlagDate[idx] && (
                    <NewFlag
                      startDate={dropDownNewFlagDate[idx]}
                      id={`mega-menu-${id}`}
                      style={{ top: -8, left: -8 }}
                    />
                  )}
                  <DropdownToggle
                    data-testid={`toggle-${dropDownToggle}`}
                    className="px-4 py-3 d-flex align-items-center"
                    style={{
                      lineHeight: "1.43",
                    }}
                    caret
                    nav
                  >
                    {dropDownToggle}
                    <Down16 className={`toggle-chevron pe-none ms-2 ${id === openDropdown ? "active" : ""}`} />
                  </DropdownToggle>
                  <DropdownMenu
                    data-testid={`display-menu-${dropDownToggle}`}
                    className="dropdown-mega-menu border-top start-0 end-0 py-7 top-100 shadow-sm"
                    style={{ borderBottom: "1px solid #dadada" }}
                    tabIndex={0}
                  >
                    <div className="container-xxl">
                      <div className="row">
                        {/* FEATURE COLUMN */}
                        <div className="col-4 mb-6">
                          <div
                            style={{
                              padding: "80px 30px",
                              border: "1px solid #d8d8d8",
                              borderRadius: "12px",
                            }}
                          >
                            <p data-testid={`menu-feature-${header}`} className="fs-2">
                              {header}
                            </p>
                            <p className="mb-6 nav-col-title fs-base">{description}</p>
                            {links[0] && (
                              <div>
                                {links[0].newFlag && (
                                  <NewFlag
                                    {...links[0].newFlag}
                                    id={`mega-menu-${featureColumn.id}`}
                                    style={{ top: -10, left: -15 }}
                                  />
                                )}
                                <LinkButton
                                  href={links[0].href}
                                  name={`${textToSlug(links[0].name)}`}
                                  onClick={toggleDisplay}
                                  level="tertiary"
                                  theme="darker"
                                  rounded="pill"
                                  size="sm"
                                >
                                  {links[0].name}
                                </LinkButton>
                              </div>
                            )}
                          </div>
                        </div>
                        {/* COLUMNS */}
                        <div
                          style={{ flexBasis: "0%" }}
                          className="position-relative d-flex p-0 flex-grow-1  flex-shrink-1"
                        >
                          {columns
                            .sort((a, b) => a.dropDownMenu.id.localeCompare(b.dropDownMenu.id))
                            .map((column) => {
                              const {
                                id,
                                dropDownMenu: { header, links, columnLink },
                              } = column;
                              return (
                                <div key={id} className={`col-lg col-xl-3`}>
                                  {column?.dropDownMenu?.header && <h6 className="ps-6">{header}</h6>}
                                  <Nav>
                                    {links.map((link) => {
                                      const { id, href, title, description, hide, newFlag } = link;

                                      return hide ? null : (
                                        <NavItem
                                          key={id}
                                          data-testid="nav-link"
                                          className={`mb-0 px-6 pb-1 ${links.length > 1 ? "w-100" : ""} ${
                                            activeNavItem === id ? "text-bg-light rounded-2" : ""
                                          }`}
                                          onMouseEnter={() => handleOnMouseEnter(id)}
                                          onMouseLeave={() => handleOnMouseLeave()}
                                        >
                                          <Link
                                            name={`${textToSlug(title)}-mega-menu-content-link`}
                                            onClick={() => {
                                              trackEvent({
                                                name: `${textToSlug(dropDownToggle)}-mega-menu-${textToSlug(
                                                  description,
                                                )}-toggle-link_click`,
                                              });
                                              toggleDisplay();
                                            }}
                                            hideExternalLinkIcon={true}
                                            href={href}
                                            className="d-block link-primary"
                                          >
                                            {newFlag && (
                                              <NewFlag
                                                {...newFlag}
                                                id={`mega-menu-${id}`}
                                                style={{ top: -8, left: -32 }}
                                              />
                                            )}
                                            <span style={{ fontSize: "18px" }} className="fw-bold">
                                              {title}
                                            </span>
                                            <p className="fs-base mb-0">{description}</p>
                                          </Link>
                                        </NavItem>
                                      );
                                    })}
                                  </Nav>
                                  {columnLink && (
                                    <Link
                                      name={`explore-${textToSlug(header)}`}
                                      onClick={toggleDisplay}
                                      href={columnLink?.href}
                                      className="link-primary link-border fs-base ms-6 mt-4"
                                    >
                                      <span
                                        style={{
                                          color: "rgba(17, 17, 17, 0.84)",
                                        }}
                                      >
                                        {columnLink?.name}
                                      </span>
                                    </Link>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              );
            })}
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  );
};
