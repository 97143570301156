"use client";
import { useEffect, useState } from "react";

import { Modal } from "../../components/modal";
import { trackEvent } from "../../utils/event-tracker";
import { HelpAndSupportFormWizard } from "./wizard";

export function HelpAndSupportFormModal({ show = false, onHide = () => {} }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [isDirty, setIsDirty] = useState(false);
  useEffect(() => {
    if (show) {
      trackEvent({ name: "help-and-support-modal-opened" });
    }
  }, [show]);
  return (
    <Modal
      size="lg"
      backdrop="static"
      isOpen={show}
      hasCancelDialog={isDirty && currentStep < 4}
      onClose={() => {
        setIsDirty(false);
        onHide();
      }}
      header="Support and feedback"
    >
      <HelpAndSupportFormWizard onDirty={() => setIsDirty(true)} onStepChange={setCurrentStep} />
    </Modal>
  );
}
