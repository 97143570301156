import { ReactNode } from "react";

export enum ToolNames {
  ArgoCD = "ArgoCD",
  AzureDevOps = "Azure DevOps",
  ArtifactoryJFrog = "Artifactory",
  Confluence = "Confluence",
  FeatureHub = "FeatureHub",
  Grafana = "Grafana",
  MixPanel = "MixPanel",
  Slack = "Slack",
  SonarQube = "SonarQube",
  VisualStudioSubscriptions = "Visual Studio Subscription",
  AzureAppConfiguration = "Azure App Configuration",
  AWSEvidently = "CloudWatch Evidently",
  DBeaver = "DBeaver",
  DockeriseContainerTools = "Docker",
  Terraform = "Terraform",
  VSCode = "VSCode & Visual Studio",
  Ansible = "Ansible",
  AppCenter = "App Center",
  Jenkins = "Jenkins",
  Splunk = "Splunk",
}

export enum Images {
  ArgoCD = "/images/argo-cd.svg",
  AzureDevOps = "/images/azure.svg",
  ArtifactoryJFrog = "/images/artifactory.svg",
  Confluence = "/images/confluence.svg",
  FeatureHub = "/images/feature-hub.svg",
  Grafana = "/images/grafana.svg",
  MixPanel = "/images/mix-panel.svg",
  Slack = "/images/slack.svg",
  SonarQube = "/images/sonar-qube.svg",
  VisualStudioSubscriptions = "/images/vs-subscriptions.svg",
  AzureAppConfiguration = "/images/azure.svg",
  AWSEvidently = "/images/aws.svg",
  DBeaver = "/images/dbeaver.svg",
  DockeriseContainerTools = "/images/docker.svg",
  Terraform = "/images/terraform.svg",
  VSCode = "/images/vs-code.svg",
  Ansible = "/images/ansible.svg",
  AppCenter = "/images/app-center.svg",
  Jenkins = "/images/jenkins.svg",
  Splunk = "/images/splunk.svg",
}

export enum Labels {
  Recommended = "Recommended",
  Supported = "Supported",
  Deprecated = "Deprecated",
}

export enum ToolDescriptions {
  ArgoCD = "Argo CD is a Kubernetes controller, responsible for continuously monitoring all running applications and comparing their live state.",
  AzureDevOps = "Azure DevOps (ADO) is the strategic digital platform for source code repos, version control and Agile Activity Management in bp.",
  ArtifactoryJFrog = "JFrog Artifactory is a universal artifact repository manager for managing software packages across the development lifecycle.",
  Confluence = "Confluence is content collaboration software for bp Technology used for product, KDD and feature documentation.",
  FeatureHub = "FeatureHub is an open-source feature flag, enabling teams to display features without needing to redeploy their applications.",
  Grafana = "Grafana is a powerful data visualization and analytics tool that enables users to create interactive dashboards, charts, and graphs.",
  MixPanel = "Mixpanel is a business analytics service that tracks user interactions with web and mobile applications for targeted communication.",
  Slack = "Slack is a chat-based team collaboration tool with channels for teams, direct message chats and plugin integrations with external apps.",
  SonarQube = "SonarQube is an open-source platform for continuous inspection of code quality, detecting bugs and vulnerabilities through static analysis.",
  VisualStudioSubscriptions = "Visual Studio Subscriptions provide access to Microsoft development tools, licensed per user for dev/test environments only.",
  AzureAppConfiguration = "Azure App Configuration provides a service to centrally manage application settings and feature flags for distributed cloud applications.",
  AWSEvidently = "AWS Evidently is a service that allows safe testing of new features by serving them to a percentage of users.",
  DBeaver = "DBeaver is an open-source, multi-platform database tool that supports various databases through a JDBC driver.",
  DockeriseContainerTools = "Dockerise Container Tools provide essential resources and guides for managing containers, including Docker, Podman, Colima, and Rancher.",
  Terraform = "Terraform is an open-source infrastructure as code tool by HashiCorp, allowing users to define and provision infrastructure using a declarative configuration language.",
  VSCode = "VSCode is a free source-code editor by Microsoft for Windows, Linux, and macOS, supporting debugging, syntax highlighting, and extensions.",
  Ansible = "Ansible is an open-source automation tool for configuration management, application deployment, and task automation.",
  AppCenter = "App Center is a cloud-based service for building, testing, distributing, and monitoring mobile applications.",
  Jenkins = "Jenkins is an open-source automation server used for building, testing, and deploying software projects.",
  Splunk = "Splunk is a software platform for searching, monitoring, and analyzing machine-generated data via a web-style interface.",
}

export type Tools = {
  id: string;
  name: ToolNames;
  icon: ReactNode;
  description: string;
  label: keyof typeof Labels;
};

export const toolsList: Tools[] = [
  {
    id: "1",
    name: ToolNames.ArgoCD,
    icon: (
      <img src={Images.ArgoCD} alt={ToolNames.ArgoCD} className="x5-me-2" style={{ width: "20px", height: "20px" }} />
    ),
    description: ToolDescriptions.ArgoCD,
    label: Labels.Recommended,
  },
  {
    id: "2",
    name: ToolNames.AzureDevOps,
    icon: (
      <img
        src={Images.AzureDevOps}
        alt={ToolNames.AzureDevOps}
        className="x5-me-2"
        style={{ width: "20px", height: "20px" }}
      />
    ),
    description: ToolDescriptions.AzureDevOps,
    label: Labels.Recommended,
  },
  {
    id: "3",
    name: ToolNames.ArtifactoryJFrog,
    icon: (
      <img
        src={Images.ArtifactoryJFrog}
        alt={ToolNames.ArtifactoryJFrog}
        className="x5-me-2"
        style={{ width: "20px", height: "20px" }}
      />
    ),
    description: ToolDescriptions.ArtifactoryJFrog,
    label: Labels.Recommended,
  },
  {
    id: "4",
    name: ToolNames.Confluence,
    icon: (
      <img
        src={Images.Confluence}
        alt={ToolNames.Confluence}
        className="x5-me-2"
        style={{ width: "20px", height: "20px" }}
      />
    ),
    description: ToolDescriptions.Confluence,
    label: Labels.Recommended,
  },
  {
    id: "5",
    name: ToolNames.FeatureHub,
    icon: (
      <img
        src={Images.FeatureHub}
        alt={ToolNames.FeatureHub}
        className="x5-me-2"
        style={{ width: "20px", height: "20px" }}
      />
    ),
    description: ToolDescriptions.FeatureHub,
    label: Labels.Supported,
  },
  {
    id: "6",
    name: ToolNames.Grafana,
    icon: (
      <img src={Images.Grafana} alt={ToolNames.Grafana} className="x5-me-2" style={{ width: "20px", height: "20px" }} />
    ),
    description: ToolDescriptions.Grafana,
    label: Labels.Recommended,
  },
  {
    id: "7",
    name: ToolNames.MixPanel,
    icon: (
      <img
        src={Images.MixPanel}
        alt={ToolNames.MixPanel}
        className="x5-me-2"
        style={{ width: "20px", height: "20px" }}
      />
    ),
    description: ToolDescriptions.MixPanel,
    label: Labels.Recommended,
  },
  {
    id: "8",
    name: ToolNames.Slack,
    icon: (
      <img src={Images.Slack} alt={ToolNames.Slack} className="x5-me-2" style={{ width: "20px", height: "20px" }} />
    ),
    description: ToolDescriptions.Slack,
    label: Labels.Recommended,
  },
  {
    id: "9",
    name: ToolNames.SonarQube,
    icon: (
      <img
        src={Images.SonarQube}
        alt={ToolNames.SonarQube}
        className="x5-me-2"
        style={{ width: "20px", height: "20px" }}
      />
    ),
    description: ToolDescriptions.SonarQube,
    label: Labels.Recommended,
  },
  {
    id: "10",
    name: ToolNames.VisualStudioSubscriptions,
    icon: (
      <img
        src={Images.VisualStudioSubscriptions}
        alt={ToolNames.VisualStudioSubscriptions}
        className="x5-me-2"
        style={{ width: "20px", height: "20px" }}
      />
    ),
    description: ToolDescriptions.VisualStudioSubscriptions,
    label: Labels.Recommended,
  },
  {
    id: "11",
    name: ToolNames.AzureAppConfiguration,
    icon: (
      <img
        src={Images.AzureAppConfiguration}
        alt={ToolNames.AzureAppConfiguration}
        className="x5-me-2"
        style={{ width: "20px", height: "20px" }}
      />
    ),
    description: ToolDescriptions.AzureAppConfiguration,
    label: Labels.Supported,
  },
  {
    id: "12",
    name: ToolNames.AWSEvidently,
    icon: (
      <img
        src={Images.AWSEvidently}
        alt={ToolNames.AWSEvidently}
        className="x5-me-2"
        style={{ width: "20px", height: "20px" }}
      />
    ),
    description: ToolDescriptions.AWSEvidently,
    label: Labels.Supported,
  },
  {
    id: "13",
    name: ToolNames.DBeaver,
    icon: (
      <img src={Images.DBeaver} alt={ToolNames.DBeaver} className="x5-me-2" style={{ width: "20px", height: "20px" }} />
    ),
    description: ToolDescriptions.DBeaver,
    label: Labels.Supported,
  },
  {
    id: "14",
    name: ToolNames.DockeriseContainerTools,
    icon: (
      <img
        src={Images.DockeriseContainerTools}
        alt={ToolNames.DockeriseContainerTools}
        className="x5-me-2"
        style={{ width: "20px", height: "20px" }}
      />
    ),
    description: ToolDescriptions.DockeriseContainerTools,
    label: Labels.Supported,
  },
  {
    id: "15",
    name: ToolNames.Terraform,
    icon: (
      <img
        src={Images.Terraform}
        alt={ToolNames.Terraform}
        className="x5-me-2"
        style={{ width: "20px", height: "20px" }}
      />
    ),
    description: ToolDescriptions.Terraform,
    label: Labels.Supported,
  },
  {
    id: "16",
    name: ToolNames.VSCode,
    icon: (
      <img src={Images.VSCode} alt={ToolNames.VSCode} className="x5-me-2" style={{ width: "20px", height: "20px" }} />
    ),
    description: ToolDescriptions.VSCode,
    label: Labels.Supported,
  },
  {
    id: "17",
    name: ToolNames.Ansible,
    icon: (
      <img src={Images.Ansible} alt={ToolNames.Ansible} className="x5-me-2" style={{ width: "20px", height: "20px" }} />
    ),
    description: ToolDescriptions.Ansible,
    label: Labels.Deprecated,
  },
  {
    id: "18",
    name: ToolNames.AppCenter,
    icon: (
      <img
        src={Images.AppCenter}
        alt={ToolNames.AppCenter}
        className="x5-me-2"
        style={{ width: "20px", height: "20px" }}
      />
    ),
    description: ToolDescriptions.AppCenter,
    label: Labels.Deprecated,
  },
  {
    id: "19",
    name: ToolNames.Jenkins,
    icon: (
      <img src={Images.Jenkins} alt={ToolNames.Jenkins} className="x5-me-2" style={{ width: "20px", height: "20px" }} />
    ),
    description: ToolDescriptions.Jenkins,
    label: Labels.Deprecated,
  },
  {
    id: "20",
    name: ToolNames.Splunk,
    icon: (
      <img src={Images.Splunk} alt={ToolNames.Splunk} className="x5-me-2" style={{ width: "20px", height: "20px" }} />
    ),
    description: ToolDescriptions.Splunk,
    label: Labels.Deprecated,
  },
];
