import { khubHrefMapper } from "../../utils/khub-href-mapper";
import { KHUB_LIBRARY_HREF } from "../../views/khub/breadcrumb-container";
import { useIsAuthenticated } from "../../hooks/use-is-authenticated";
import { Labels, toolsList } from "../../utils/tools-list";

export enum DropdownEnum {
  Admin = "Admin",
  Documentation = "Documentation",
  Products = "Products",
  Tools = "Tools",
}

type FeatureColumnLinkType = {
  name: string;
  href: string;
  newFlag?: {
    startDate: string;
  };
};

type FeatureColumnType = {
  id: string;
  dropDownMenu: {
    header: string;
    description: string;
    links: FeatureColumnLinkType[];
  };
};

type ColumnLinkType = {
  id: string;
  title?: string;
  href: string;
  description: string;
  hide?: boolean;
  newFlag?: {
    startDate: string;
  };
};

type ColumnType = {
  id: string;
  dropDownMenu: {
    id: string;
    header: string;
    links: ColumnLinkType[];
    columnLink?: { name: string; href: string };
  };
  hide?: boolean;
};

type MenuDataType = {
  id: string;
  dropDownToggle: string;
  featureColumn: FeatureColumnType;
  columns: ColumnType[];
};

const teamsProductsMenu: MenuDataType = {
  id: "5",
  dropDownToggle: "Products",
  featureColumn: {
    id: "5.1",
    dropDownMenu: {
      header: "Products",
      description: `Find key information about each of bp's products, the teams that build them as well as their product metrics and maturity scores.`,
      links: [],
    },
  },
  columns: [
    {
      id: "5.2",
      dropDownMenu: {
        id: "1",
        header: "Products",
        links: [
          {
            id: "5.2.1",
            title: "",
            href: `/my-products`,
            description: "My products",
            newFlag: {
              startDate: "2023-10-25",
            },
          },
          {
            id: "5.2.2",
            title: "",
            href: `/all-products`,
            description: "All products",
          },
        ],
      },
    },
  ],
};

type ToolItem = {
  id: string;
  title: string;
  href: string;
  description: string;
};

export function getToolsByLabel(label: keyof typeof Labels): ToolItem[] {
  return toolsList
    .filter((item) => item.label === label)
    .map((item) => {
      return {
        id: item.id,
        title: "",
        href: `/technology/tools/tool/${item.name}?source=tools`,
        description: item.name,
      };
    });
}

const toolsMenu: MenuDataType = {
  id: "3",
  dropDownToggle: "Tools",
  featureColumn: {
    id: "3.1",
    dropDownMenu: {
      header: "Tools",
      description: "Our endorsed tools support teams across all areas of software delivery.",
      links: [
        {
          name: "All tools",
          href: "/technology/tools?context=tools",
        },
      ],
    },
  },
  columns: [
    {
      id: "3.1",
      dropDownMenu: {
        id: "1",
        header: "Tooling hubs",
        links: [
          {
            id: "3.3.1",
            title: "",
            href: `/technology/tools?context=tools`,
            description: "All tools",
          },
          {
            id: "3.3.2",
            title: "",
            href: `/marketplace/components/type/executablefunctions`,
            description: "All automations",
          },
        ],
      },
    },
    {
      id: "3.2",
      dropDownMenu: {
        id: "2",
        header: "Recommended tools",
        links: getToolsByLabel(Labels.Recommended),
      },
      hide: false,
    },
    {
      id: "3.3",
      dropDownMenu: {
        id: "3",
        header: "Supported tools",
        links: getToolsByLabel(Labels.Supported),
      },
      hide: false,
    },
  ],
};

const documentationMenu: MenuDataType = {
  id: "4",
  dropDownToggle: "Documentation",
  featureColumn: {
    id: "4.1",
    dropDownMenu: {
      header: "Documentation",
      description: `The hub of technical knowledge at bp.`,
      links: [
        {
          name: "Explore all documentation",
          href: KHUB_LIBRARY_HREF,
        },
      ],
    },
  },
  columns: [
    {
      id: "4.2",
      dropDownMenu: {
        id: "1",
        header: "Documents",
        links: [
          {
            id: "4.2.1",
            title: "",
            href: khubHrefMapper(`Architecture_Artefacts`),
            description: "Architectural artifacts",
          },
          {
            id: "4.2.2",
            title: "",
            href: khubHrefMapper(`Create`),
            description: "bp Create",
          },
          {
            id: "4.2.3",
            title: "",
            href: khubHrefMapper(`Digital-Security`),
            description: "Developer security services",
          },
          {
            id: "4.2.4",
            title: "",
            href: khubHrefMapper(`DevOps-Methodology`),
            description: "DevOps methodology",
          },
          {
            id: "4.2.5",
            title: "",
            href: khubHrefMapper(`Digital-Standards`),
            description: "Digital standards",
          },
          {
            id: "4.2.6",
            title: "",
            href: khubHrefMapper(`Myrge`),
            description: "Myrge",
          },
          {
            id: "4.2.7",
            title: "",
            href: khubHrefMapper(`The-Product-Wiki`),
            description: "The product wiki",
          },
          {
            id: "4.2.8",
            title: "",
            href: khubHrefMapper(`TRM`),
            description: "TRM Documentation",
          },
        ],
      },
    },
    {
      id: "4.3",
      dropDownMenu: {
        id: "2",
        header: "Help",
        links: [
          {
            id: "4.3.1",
            title: "",
            href: khubHrefMapper(`Help/How-to-create-pages-and-folders`),
            description: "How to create documents",
          },
          {
            id: "4.3.2",
            title: "",
            href: khubHrefMapper(`Help/How-to-edit-content`),
            description: "How to edit documents",
          },
          {
            id: "4.3.3",
            title: "",
            href: khubHrefMapper(`Help/Markdown-Cheatsheet`),
            description: "How to format documents",
          },
          {
            id: "4.3.4",
            title: "",
            href: khubHrefMapper(`Architecture_Artefacts/How_to_Create_KDDs`),
            description: "How to create KDDs",
          },
        ],
      },
    },
  ],
};

const adminMenu: MenuDataType = {
  id: "0",
  dropDownToggle: "Admin",
  featureColumn: {
    id: "0.1",
    dropDownMenu: {
      header: "Moderation dashboard",
      description: "Download reports and moderate submissions.",
      links: [
        {
          name: "Accelerate moderation dashboard",
          href: "/admin/moderation",
        },
      ],
    },
  },
  columns: [
    {
      id: "0.2",
      dropDownMenu: {
        id: "1",
        header: "Dashboard",
        links: [
          {
            id: "0.2.1",
            title: "",
            href: `/admin/moderation`,
            description: "Moderation tools",
          },
        ],
      },
    },
  ],
};

export const useMenuData = () => {
  const { isModerator } = useIsAuthenticated();
  return [teamsProductsMenu, toolsMenu, documentationMenu].concat(isModerator ? [adminMenu] : []);
};
