"use client";
import React from "react";
import "intro.js/introjs.css";
import "../App.scss";
import { ApolloProvider } from "@apollo/client";

import mixpanel from "mixpanel-browser";

import { useApollo } from "../apollo/config";
import { HttpsRedirect } from "../utils/helpers/https-redirect";

// import { ReactQueryDevtools } from 'react-query/devtools';
import { env } from "next-runtime-env";
import { useLocation } from "../hooks/use-location";
import { PageLayout } from "../app/page-layout";
import { AppWrapper } from "../providers/app-wrapper";
import { NuqsAdapter } from "nuqs/adapters/next/pages";

const NODE_ENV = process.env.NODE_ENV;

if (env("NEXT_PUBLIC_MIXPANEL_ID")) {
  mixpanel.init(env("NEXT_PUBLIC_MIXPANEL_ID") as string, {
    debug: NODE_ENV === "development",
    api_host: env("NEXT_PUBLIC_MIXPANEL_PROXY"),
    // @ts-expect-error api_payload_format is not in the types
    api_payload_format: NODE_ENV !== "production" ? "json" : "base64",
    secure_cookie: true,
    cross_site_cookie: true,
  });
  mixpanel.register({
    app_version: env("NEXT_PUBLIC_DEPLOY_VERSION") ?? "local",
  });
}

// We do this because we want to hide the new flag after a delay,
// but if do this in the component and it get's unmounted the component,
// the setTimeout will never fire.
export function hideNewFlagAfterDelay(setIsShowing: (value: boolean) => void) {
  setTimeout(() => setIsShowing(false), 60 * 1_000);
}

export default function App({ Component, pageProps }: { Component: any; pageProps: any }) {
  const { pathname } = useLocation();
  const apolloClient = useApollo(pageProps);
  return (
    <HttpsRedirect>
      <ApolloProvider client={apolloClient}>
        <AppWrapper>
          <NuqsAdapter>
            <PageLayout>
              <Component key={pathname} {...pageProps} />
            </PageLayout>
          </NuqsAdapter>
        </AppWrapper>
      </ApolloProvider>
    </HttpsRedirect>
  );
}

// Do not remove this snippet. This disables Next.js Automatic Static Optimization.
// When our application moves over to fetching data server-side via `getServerSideProps`,
// this will occur automatically, but at the moment, Next.js thinks it can pre-render
// our pages, which breaks the `UseRouter` hook.
// See here: https://nextjs.org/docs/pages/building-your-application/rendering/automatic-static-optimization
App.getInitialProps = async () => {
  return {};
};
