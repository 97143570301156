"use client";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Row, Col } from "reactstrap";
import { Toast } from "../components/toast";
import { NotificationType, MapEventToNotification } from "../utils/notification-mapper";
import { Footer } from "../components/footer";
import { HelpAndSupportFormModal } from "../forms/helpAndSupport/modal";
import { useWebsocket } from "../hooks/use-websocket";
import { MegaMenu2 } from "../navigation/mega-menu";
import { NavbarTop } from "../navigation/navbar-top";
import ExceptionPage from "../views/exception/exception-page";
import { useAppActions, useIsHelpAndSupportModalOpen, useToasts } from "../hooks/use-app-store";
import { useLocation } from "../hooks/use-location";
import { useFeature, useFeatureHub } from "featurehub-react-sdk";
import { DismissableBanner } from "../components/dismissable-banner";
import { SideMegaMenu } from "../navigation/mega-menu/side-mega-menu";

export const PageLayout: React.FC<PropsWithChildren> = (props) => {
  const websocket = useWebsocket();
  const location = useLocation();
  const { client } = useFeatureHub();
  useEffect(() => {
    if (client.repository().readyness === "Ready") {
      const featureFlags = Object.fromEntries(
        Array.from(client.repository().simpleFeatures(), ([key, value]) => [key, value === "true"]),
      );
      console.table(featureFlags);
    }
  }, [client.repository().readyness]);

  const { setToasts, setIsHelpAndSupportModalOpen } = useAppActions();

  const toasts = useToasts();
  const isHelpAndSupportModalOpen = useIsHelpAndSupportModalOpen();
  const FE_SIDE_MEGA_MENU = useFeature("FE_SIDE_MEGA_MENU");

  function handleNewNotification(notification: NotificationType) {
    const { id, title } = MapEventToNotification(notification);
    appendToast({ id, title });
  }

  function appendToast({ id, title = "You have a new notification" }: { id: string; title?: string }) {
    if (id) {
      setToasts([
        ...toasts,
        <Toast autohide key={id}>
          {title}
        </Toast>,
      ]);
    }
  }

  const [showLabels, setShowLabels] = useState(true);
  useEffect(() => {
    if (websocket) {
      websocket?.on("NEW_NOTIFICATION", handleNewNotification);
      return () => {
        websocket?.off("NEW_NOTIFICATION", handleNewNotification);
      };
    }
  }, [websocket]);

  const errorHandler = (error: any, info: any) => {
    console.error({ error, info });
  };

  return (
    <>
      <HelpAndSupportFormModal show={isHelpAndSupportModalOpen} onHide={() => setIsHelpAndSupportModalOpen(false)} />
      <div className="d-flex flex-column">
        <div className="align-self-center" style={{ marginLeft: showLabels ? "180px" : "80px" }}>
          <DismissableBanner color={"info"} name={"khub-migration-notice-banner"} show={true}>
            Accelerate Documents (Knowledge Hub) is being retired on the 31st of January 2025. See details here:{" "}
            <a href="https://accelerate.bpglobal.com/knowledgehub/chB6Vb">
              Migrating technical docs from Accelerate to Confluence
            </a>
          </DismissableBanner>
        </div>
      </div>
      {FE_SIDE_MEGA_MENU ? null : (
        <>
          <NavbarTop />
          <MegaMenu2 />
        </>
      )}
      <div className={`${FE_SIDE_MEGA_MENU ? "d-flex overflow-x-hidden" : ""}`}>
        {FE_SIDE_MEGA_MENU && <SideMegaMenu showLabels={showLabels} setShowLabels={setShowLabels} />}
        {FE_SIDE_MEGA_MENU ? (
          <div
            className="main-content flex-grow-1 overflow-y-auto"
            style={{ marginLeft: showLabels ? "180px" : "80px", transition: "margin-left 1s ease-in-out" }}
          >
            <Row className="main-content-row px-3">
              <Col className="col main px-0 py-0" role="main">
                {props.children}
              </Col>
            </Row>
          </div>
        ) : (
          <div className="app-container position-relative">
            <Row className="main-content">
              <Col className={`col main px-0 py-0`} role="main">
                <ErrorBoundary
                  key={location.pathname}
                  fallbackRender={() => <ExceptionPage setIsHelpAndSupportModalOpen={setIsHelpAndSupportModalOpen} />}
                  onError={errorHandler}
                >
                  {props.children}
                </ErrorBoundary>
              </Col>
              {toasts.length > 0 && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: "2rem",
                  }}
                >
                  {toasts}
                </div>
              )}
            </Row>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};
