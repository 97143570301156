"use client";

import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import type { FC, PropsWithChildren } from "react";

export const NextProgressBar: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      {children}
      <ProgressBar height="4px" color="#2db7f1" options={{ showSpinner: true }} />
    </>
  );
};
