import { SVGProps } from "react";

export const LeadTime = ({ width = 24, height = 24, ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" {...props}>
    <g fill="#111" opacity={0.8}>
      <path d="M20.597 5.507h-2.82V3.403a.403.403 0 0 0-.807 0v2.104H7.03V3.403a.403.403 0 0 0-.806 0v2.104H3.403A.403.403 0 0 0 3 5.91v14.687c0 .223.18.403.403.403h17.194c.223 0 .403-.18.403-.403V5.91a.403.403 0 0 0-.403-.403zm-14.373.806v1.388a.403.403 0 1 0 .806 0V6.313h9.94v1.388a.403.403 0 0 0 .806 0V6.313h2.418V9.09H3.806V6.313h2.418zM3.806 20.194V9.896h16.388v10.298H3.806z" />
      <path d="M13.842 11.955a1.324 1.324 0 1 0 0 2.648 1.324 1.324 0 0 0 0-2.648zm0 1.842a.518.518 0 1 1 0-1.036.518.518 0 0 1 0 1.036zm3.685-1.842a1.324 1.324 0 1 0 0 2.648 1.324 1.324 0 0 0 0-2.648zm0 1.842a.518.518 0 1 1 0-1.036.518.518 0 0 1 0 1.036zM6.473 15.844a1.324 1.324 0 1 0 0 2.649 1.324 1.324 0 0 0 0-2.649zm0 1.843a.518.518 0 1 1 0-1.037.518.518 0 0 1 0 1.037zm3.685-1.843a1.324 1.324 0 1 0 0 2.649 1.324 1.324 0 0 0 0-2.649zm0 1.843a.518.518 0 1 1 0-1.037.518.518 0 0 1 0 1.037zm3.684-1.843a1.324 1.324 0 1 0 0 2.649 1.324 1.324 0 0 0 0-2.649zm0 1.843a.518.518 0 1 1 0-1.037.518.518 0 0 1 0 1.037zm3.685-1.843a1.324 1.324 0 1 0 0 2.649 1.324 1.324 0 0 0 0-2.649zm0 1.843a.518.518 0 1 1 0-1.037.518.518 0 0 1 0 1.037zm-12.26-3.253a.403.403 0 0 0 .57 0l.61-.61.611.61a.403.403 0 1 0 .57-.57l-.61-.61.61-.61a.403.403 0 0 0-.57-.57l-.61.61-.61-.61a.403.403 0 1 0-.57.57l.61.61-.61.61a.403.403 0 0 0 0 .57zm3.71 0a.403.403 0 0 0 .57 0l.61-.61.611.61a.403.403 0 1 0 .57-.57l-.61-.61.61-.61a.403.403 0 0 0-.57-.57l-.61.61-.61-.61a.403.403 0 0 0-.57.57l.61.61-.61.61a.403.403 0 0 0 0 .57z" />
    </g>
  </svg>
);

export function Calendar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <title>calendar</title>
      <g id="Calendar">
        <path d="M975.24669,141.08871H819.30609V24.75207a22.27723,22.27723,0,0,0-44.55445,0V141.08871H225.24669V24.75207a22.27723,22.27723,0,0,0-44.55446,0V141.08871H24.75164A22.278,22.278,0,0,0,2.47441,163.36593V975.24712a22.278,22.278,0,0,0,22.27723,22.27723H975.24669a22.278,22.278,0,0,0,22.27723-22.27723V163.36593A22.278,22.278,0,0,0,975.24669,141.08871ZM180.69223,185.64316v76.73268a22.27723,22.27723,0,0,0,44.55446,0V185.64316h549.505v76.73268a22.27722,22.27722,0,0,0,44.55445,0V185.64316H952.96946V339.10851H47.02887V185.64316ZM47.02887,952.96989V383.663H952.96946V952.96989Z" />
        <path d="M601.83819,497.52435a73.19675,73.19675,0,1,0,73.19772,73.19771A73.27936,73.27936,0,0,0,601.83819,497.52435Zm0,101.839a28.64229,28.64229,0,1,1,28.64326-28.64132A28.6733,28.6733,0,0,1,601.83819,599.36338Z" />
        <path d="M805.51626,497.52435A73.19675,73.19675,0,1,0,878.712,570.72206,73.27937,73.27937,0,0,0,805.51626,497.52435Zm0,101.839a28.64229,28.64229,0,1,1,28.64132-28.64132A28.67329,28.67329,0,0,1,805.51626,599.36338Z" />
        <path d="M194.484,712.517a73.19675,73.19675,0,1,0,73.19578,73.19771A73.27978,73.27978,0,0,0,194.484,712.517Zm0,101.839a28.64229,28.64229,0,1,1,28.64132-28.64132A28.67494,28.67494,0,0,1,194.484,814.356Z" />
        <path d="M398.16207,712.517a73.19675,73.19675,0,1,0,73.19577,73.19771A73.27978,73.27978,0,0,0,398.16207,712.517Zm0,101.839a28.64229,28.64229,0,1,1,28.64132-28.64132A28.675,28.675,0,0,1,398.16207,814.356Z" />
        <path d="M601.83819,712.517a73.19675,73.19675,0,1,0,73.19772,73.19771A73.27936,73.27936,0,0,0,601.83819,712.517Zm0,101.839a28.64229,28.64229,0,1,1,28.64326-28.64132A28.6733,28.6733,0,0,1,601.83819,814.356Z" />
        <path d="M805.51626,712.517A73.19675,73.19675,0,1,0,878.712,785.71471,73.27937,73.27937,0,0,0,805.51626,712.517Zm0,101.839a28.64229,28.64229,0,1,1,28.64132-28.64132A28.67329,28.67329,0,0,1,805.51626,814.356Z" />
        <path d="M127.81089,634.56411a22.27844,22.27844,0,0,0,31.50526,0l33.75232-33.75232,33.75232,33.75232a22.27758,22.27758,0,1,0,31.50526-31.50526l-33.75232-33.75232,33.75232-33.75232A22.27758,22.27758,0,0,0,226.82079,504.049l-33.75232,33.75232L159.31615,504.049a22.27758,22.27758,0,0,0-31.50526,31.50526l33.75232,33.75232-33.75232,33.75232A22.27727,22.27727,0,0,0,127.81089,634.56411Z" />
        <path d="M332.90448,634.56411a22.27844,22.27844,0,0,0,31.50526,0l33.75233-33.75232,33.75232,33.75232a22.27758,22.27758,0,1,0,31.50526-31.50526l-33.75232-33.75232,33.75232-33.75232A22.27758,22.27758,0,0,0,431.91439,504.049l-33.75232,33.75232L364.40974,504.049a22.27758,22.27758,0,0,0-31.50526,31.50526l33.75233,33.75232-33.75233,33.75232A22.27729,22.27729,0,0,0,332.90448,634.56411Z" />
      </g>
    </svg>
  );
}
